import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Result from '../Result'

const SidebarResults = () => {
    const data = useStaticQuery(graphql`
        {
            allResultsJson(limit: 6) {
                nodes {
                    title
                    courtHouse
                    amount
                    resultBody
                }
            }
        }
    `);
    
    var settings = {
        arrows: false,
        autoplay: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            <Slider {...settings}>
                {data.allResultsJson.nodes.map(resultSlide => (
                    <Result
                        key={resultSlide.title}
                        name={resultSlide.title}
                        courtName={resultSlide.courtHouse}
                        resultAmount={resultSlide.amount}
                        body={resultSlide.resultBody}
                        cssClass={`sidebar__result`}
                    />
                ))}
            </Slider>
        </>
    )
}

export default SidebarResults
