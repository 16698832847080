import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from 'styled-components'
import Layout from '../components/Layout'
import { Container, Sidebar, SidebarHeadingLabel, defaultTheme } from "../styles"
import { motion } from 'framer-motion'
import PageBanner from '../components/PageBanner'
import SEO from '../components/SEO'
import SidebarResults from '../components/SidebarResults'
import ContactForm from '../components/ContactForm'
import Image from 'gatsby-image'
import SidebarPhoneCallout from '../components/SidebarPhoneCallout'

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        seoTitle
        metaDesc
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }
`

const transition = {
  duration: 0.25,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const PracticeAreaTemplate = ({ pageContext, data: { mdx: project }}) => {
  return(
    <Layout>
      <SEO
        title={project.frontmatter.seoTitle}
        metaDesc={project.frontmatter.metaDesc}
      />
      <PageBanner title={project.frontmatter.title} subtitle={`Los Angeles ${project.frontmatter.title} Lawyer`} />
      <Container flex>
        <PracticeAreaBody
          initial={{
            opacity: 0,
            y: 10,
            transition
          }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.55, ...transition, duration: 0.75}
          }}
        >
          <PracticeAreaImage>
            <Image
                fluid={project.frontmatter.featuredImage.childImageSharp.fluid} 
                alt={`${project.frontmatter.title} image`}
            />
          </PracticeAreaImage>
          <MDXRenderer>
            {project.body}
          </MDXRenderer>
        </PracticeAreaBody>
        <Sidebar>
          <SidebarResults />
          <motion.div
            initial={{
              opacity: 0,
              y: 10,
              transition
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.55, ...transition, duration: 0.75}
            }}
          >
            <SidebarPhoneCallout />
            <SidebarHeadingLabel>
              Free Case Review
            </SidebarHeadingLabel>
            <ContactForm />
          </motion.div>
        </Sidebar>
      </Container>
    </Layout>
  )
}

export default PracticeAreaTemplate

const PracticeAreaBody = styled(motion.div)`
  padding: 48px 0;
  word-break: break-word;

  @media(min-width: 900px) {
    padding: 96px 40px 96px 0;
    width: 65%;
  }

  .cta {
    margin: 24px 0;
  }

  p {
    margin: 0 0 20px;
    max-width: 820px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.618;

    a {
      font-weight: 500;
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          border-bottom: 1px solid ${defaultTheme.darkBlue};
        }
      }
    }
  }

  h2 {
    margin: 0 0 24px;
    font-size: 26px;

    @media(min-width: 900px) {
      font-size: 30px;
    }
  }

  li {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.618;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 25px;
    list-style: disc;
  }

  ol {
    margin-bottom: 20px;
    padding-left: 25px;
  }

  h3 {
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 500;
  }
`;

const PracticeAreaImage = styled.div`
  margin-bottom: 32px;
  width: 100%;
`;